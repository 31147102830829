.app {
  font-family: Roboto, Helvetica, sans-serif, Arial;
  max-width: 1000px;
  margin: 0 auto;
}
.plain-text {
  font-size: 20px;
  line-height: 30px;
}
.plain-text a {
  text-decoration: none;
  color: black;
  border-bottom: 2px dotted black;
}
.plain-text a:hover {
  color: #066fa8;
  border-bottom: 4px solid #066fa8;
}

.header {
  font-family: Roboto Condensed, Helvetica, sans-serif, Arial;
  font-size: 24px;
  padding: 20px;
  color: black;
  transition: color ease-out 0.1s;
}
.title {
  font-weight: bold;
  cursor: pointer;
  color: black;
  text-decoration: none;
}
.title:hover {
  color: #066fa8;
}
.subtitle {
  border-left: 1px solid black;
}
.nav {
  float: right;
  display: block;
}
.nav-link {
  margin-right: 20px;
  font-size: 18px;
  border-bottom: 2px solid black;
  cursor: pointer;
  color: black;
  line-height: 24px;
  transition: color ease-out 0.1s;
  text-decoration: none;
}
.nav-link:hover {
  color: #066fa8;
  border-bottom: 4px solid #066fa8;
}

.nav-link-selected {
  font-weight: bold;
  color: #066fa8;
  border-bottom: 4px solid #066fa8;
}

.body {
  width: 100%;
}
.body-title {
  font-size: 28px;
  font-weight: bold;
  text-transform: capitalize;
  margin-top: 40px;
  margin-left: 10px;
  border-bottom: 5px solid black;
  width: auto;
}

/* ----------------------------------------------- HOME and ABOUT ----------------------------------------------- */
.home,
.about {
  padding: 20px;
  /* width: 100%; */
}
.home-img {
  width: 100%;
}
.home-title {
  font-size: 20px;
}
.home-text {
  font-size: 20px;
}

/* ----------------------------------------------- LIST OF PARTICIPANTS ----------------------------------------------- */
.participant-list-title {
  font-family: Roboto Condensed, Helvetica, sans-serif, Arial;
  font-weight: bold;
  font-size: 36px;
  margin: 20px;
  padding-left: 10px;
  border-left: 5px solid #066fa8;
}

.gallery {
  margin: 20px;
  margin-right: 0px;
  box-sizing: border-box;
}

.gallery:after {
  content: "";
  display: table;
  clear: both;
}

.item {
  text-decoration: none;
  font-family: Roboto Condensed, Helvetica, sans-serif, Arial;
  font-weight: bold;
  cursor: pointer;
  float: left;
  font-size: 20px;
  width: 33.33%;
  margin-bottom: 20px;
}

.headshot {
  border-radius: 0px;
  width: calc(100% - 20px);
  height: 200px;
  object-fit: cover;
  outline: 10px solid #066fa800;
  outline-offset: -10px;
  transition: outline-color ease-out 0.1s;
}

.p-name-title {
  /* 10px is for padding for text ends */
  width: calc(100% - 20px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
  transition: color ease-out 0.1s;
  /* font-size: 1px; */
}
.p-inst {
  width: calc(100% - 20px);
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
  transition: color ease-out 0.1s;
  /* font-style: italic; */
}
.p-inst-prefix {
  /* font-style: normal; */
}

.item:hover .headshot {
  outline: 10px solid #066fa8;
  outline-offset: -10px;
}

.item:hover .p-name-title,
.item:hover .p-inst {
  color: #066fa8;
}

/* ----------------------------------------------- PARTICIPANT PAGE ----------------------------------------------- */
.participant-detail {
  margin: 20px;
  margin-bottom: 40px;
}
.participant-detail-title {
  font-family: Roboto Condensed, Helvetica, sans-serif, Arial;
  font-weight: bold;
  font-size: 36px;
  padding-left: 10px;
  border-left: 5px solid #066fa8;
}
.participant-detail-subtitle {
  padding-top: 10px;
  font-weight: 400;
  font-size: 28px;
  /* font-style: italic; */
}
.participant-detail-subtitle a {
  text-decoration: none;
  color: black;
  border-bottom: 2px dotted black;
}
.participant-detail-subtitle a:hover {
  color: #066fa8;
  border-bottom: 4px solid #066fa8;
}
.participant-detail-video {
  margin-top: 20px;
  width: 100%;
  height: 540px;
}
.md {
  font-family: Roboto, Helvetica, sans-serif, Arial;
  font-size: 18px;
  line-height: 26px;
}
.md h2 {
  text-transform: capitalize;
  /* margin-top: 30px; */
}
.md hl {
  color: #066fa8;
  font-weight: bold;
}
.md blockquote {
  font-family: Roboto Condensed, Helvetica, sans-serif, Arial;
  font-style: italic;
  font-size: 24px;
  line-height: 32px;
  margin-right: 0px;
  margin-left: 0px;
  padding-left: 30px;
  border-left: 5px solid lightgray;
  /* background-color: lightgray; */
  /* font-weight: 400; */
}

.release-data-desc {
  position: relative;
  width: 100%;
  text-align: right;
  margin-top: 30px;
  font-size: 18px;
  font-style: italic;
}
.h-splitter {
  border-top: 1px solid black;
  margin-top: 40px;
  margin-left: 20px;
  width: calc(100% - 40px);
}
.next-prev-button {
  font-family: Roboto Condensed, Helvetica, sans-serif, Arial;
  width: calc(100% - 20px);
  margin-left: 10px;
  /* padding-left: 20px; */
  /* padding-right: 20px; */
  padding-top: 50px;
  padding-bottom: 50px;
  /* background-color: lightgray; */
}
.prev-button,
.next-button {
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  text-decoration: none;
  color: black;
}
.prev-button:hover,
.next-button:hover {
  color: #066fa8;
  /* border-bottom: 4px solid #066FA8; */
}
.prev-button-hidden {
  opacity: 0;
}
.next-button {
  float: right;
}
/* ----------------------------------------------- FOOTER ----------------------------------------------- */
.footer {
  height: 120px;
  border-top: 3px solid black;

  margin-left: 20px;
  margin-right: 20px;

  padding-top: 20px;
  /* padding-left: 10px; */
  /* padding-right: 10px; */

  line-height: 28px;
  font-weight: bold;
}

.footer a:link,
.footer a:visited,
.footer .alt-link {
  text-decoration: none;
  color: black;
}

.footer a,
.footer .alt-link {
  cursor: pointer;
  transition: color ease-out 0.1s;
  border-bottom: 2px dotted black;
}

.footer a:hover,
.footer .alt-link:hover {
  color: #066fa8;
  border-bottom: 4px solid #066fa8;
}

.footer img {
  float: right;
  display: block;
}

/* ----------------------------------------------- RESPONSIVE ----------------------------------------------- */
@media screen and (max-width: 1000px) {
  .nav {
    margin-top: 20px;
    float: none;
  }
  .subtitle {
    font-size: 16px;
  }
  .item {
    width: 50%;
  }
  .participant-detail-video,
  .participant-detail-video iframe {
    height: 400px !important;
  }
  .next-prev-button {
    text-align: center;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .next-prev-button a {
    display: block;
    line-height: 50px;
  }
  .next-button {
    float: none;
  }
}

@media screen and (max-width: 600px) {
  .subtitle {
    display: block;
  }
  .item {
    width: 100%;
  }
  .headshot {
    height: auto;
  }
  .participant-detail-video,
  .participant-detail-video iframe {
    height: 280px !important;
  }
  .md blockquote {
    padding-left: 15px;
  }
}
